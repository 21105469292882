import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import * as cases from '../cases.module.scss';
import * as smartplug from './smartplug.module.scss';
import { SiteLayout, Head, Holder, Intro } from '~/components';

const CasesSmartplugPage = () => (
  <SiteLayout>
    <Head title="ブロックチェーンを活用した電力取引サービス" />

    <StaticImage
      alt=""
      src="./images/hero_smartplug.jpg"
      quality={100}
      className={cases.introImg}
    />

    <Intro>
      <Holder
        tag="福島県再生可能エネルギー関連技術実証研究支援事業"
        phrase="ブロックチェーンを活用した<br />電力取引サービス"
        title="ディマンドリスポンス実証"
        description="再生可能エネルギー普及拡大に向けた取り組みとして平成29年から平成31年度の3ヵ年計画による事業を実施しました。本事業は直接的な再生可能エネルギーの増加や新技術を示すものではなく、消費者に対する再生エネルギーを使用する優位性を示し、消費者自らが選択する意識を持って再生可能エネルギーを使用する動機付けを行うことを目的として、スマートプラグによる電力管理及びブロックチェーンを活用した電力取引サービスの実証事業を福島県内で行いました。"
        type="株式会社エナリス共同実証事業"
      />
    </Intro>

    <section className={`${cases.sectionBlur} ${smartplug.distance}`}>
      <div className={cases.inner}>
        <h2>概要</h2>
        <p className={smartplug.sectionText}>株式会社エナリスと共同で、エアコンのスマートコントロールや高齢者向けの見守りサービス、エアコンの一斉制御によるディマンドリスポンスの実証実験を行うため、多機能スマートプラグ「SmartPlug」の開発・製造、ブロックチェーン基盤の設計・開発、電力管理アプリケーションの開発・運用、実証事業の管理・運用等を行いました。3ヵ年かけて、スマートプラグの改良・電圧変更版の製造や、電力取引の通信方式の改善・ブロックチェーン技術の適応範囲拡大、アプリケーションの改善等、実証範囲の拡大やサービスの充実化を進めました。</p>

        <div className={smartplug.summaryImages}>
          <StaticImage
            alt=""
            src="./images/img_summary01.png"
            quality={80}
            className={smartplug.summaryImage}
          />
          <StaticImage
            alt=""
            src="./images/img_summary02.png"
            quality={80}
            className={smartplug.summaryImage}
          />
          <StaticImage
            alt=""
            src="./images/img_summary03.png"
            quality={80}
            className={smartplug.summaryImage}
          />
        </div>

        <div className={smartplug.mainTopic}>
          <div className={smartplug.sectionBox}>
            <h2 className={smartplug.sectionTitle}>ブロックチェーンの活用</h2>
            <p>ブロックチェーンを活用することで得るメリットは情報の信頼性が上がることです。特に仮想通貨や金融取引等、重要なデータのやり取りを可能にする技術といえます。本事業ではディマンドリスポンスによって発生したインセンティブを最終的に消費者間でやり取りさせるためブロックチェーンでのデータ管理を実施しました。事業内では一斉制御のインセンティブ配当となりましたが、個人間取引も対応可能です。</p>

            <h2 className={smartplug.sectionTitle}>保有するノウハウ</h2>
            <p>本実証において、PSEを取得したスマートメータの設計・製造と、実運用を見越したサービス提供及び、ブロックチェーン基盤を活用したポイント取引の実績・ノウハウを保有しています。弊社では、他システムや開発案件への展開が可能です。
        お気軽にお問い合わせください。</p>
          </div>

          <div className={smartplug.topicImages}>
            <StaticImage
              alt=""
              src="./images/img_blockchain.png"
              quality={80}
              className={smartplug.topicImage01}
            />
            <StaticImage
              alt=""
              src="./images/img_knowhow.png"
              quality={80}
              className={smartplug.topicImage02}
            />
          </div>
        </div>
      </div>
    </section>

  </SiteLayout>
);

export default CasesSmartplugPage;