// extracted by mini-css-extract-plugin
export var distance = "smartplug__distance-XJ30I";
export var mainTopic = "smartplug__mainTopic-s9Qvy";
export var sectionBox = "smartplug__sectionBox-v4cw7";
export var sectionText = "smartplug__sectionText-8YzQG";
export var sectionTitle = "smartplug__sectionTitle-wKzAA";
export var summaryImage = "smartplug__summaryImage-yQbTU";
export var summaryImages = "smartplug__summaryImages--rmr4";
export var topicImage01 = "smartplug__topicImage01-Vknwn";
export var topicImage02 = "smartplug__topicImage02-EyjiA";
export var topicImages = "smartplug__topicImages-fxqdu";